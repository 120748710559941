import React from "react";
import { useBreakpoint, Stack, Heading, Button } from "@chakra-ui/react";

import { Exclaim } from "./Exclaim";
import { ContactLink } from "./ContactLink";

import ShapeCubeSvg from "../images/svg/ShapeCube.svg";
import ShapeZigZagSvg from "../images/svg/ShapeZigZag.svg";

export const HiringExclaim: React.FC = () => {
  const breakpoint = useBreakpoint() || "base";
  const isSmallScreen = ["base", "sm"].includes(breakpoint);

  return (
    <Exclaim
      topRightAsset={<ShapeCubeSvg height={200} />}
      bottomLeftAsset={<ShapeZigZagSvg height={200} />}
    >
      <Stack direction="column" align="center" spacing={12} flex={1}>
        <Stack
          direction="column"
          align={["flex-start", "flex-start", "center"]}
          spacing={6}
        >
          <Heading textAlign="center" fontSize={["3xl", "3xl", "4xl", "5xl"]}>
            Not happy with the quality of talent you're seeing?
          </Heading>
          <Heading
            fontSize={["lg", "lg", "xl", "2xl"]}
            fontWeight="regular"
            opacity={0.8}
          >
            Let's work together and find you the right people.
          </Heading>
        </Stack>
        <ContactLink>
          <Button
            isFullWidth={isSmallScreen}
            colorScheme="whiteAlpha"
            color="white"
            size="lg"
            variant="outline"
          >
            HELP ME HIRE
          </Button>
        </ContactLink>
      </Stack>
    </Exclaim>
  );
};
