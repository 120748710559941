import React from "react";
import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";

import LogoSvg from "../images/svg/LogoLight.svg";

export const Footer: React.FC = () => (
  <Stack background="gray.800" paddingY={16} paddingX={[8, 8, 0]} color="white">
    <Container maxWidth="container.lg">
      <Stack
        direction={["column", "column", "row"]}
        justify={["center", "center", "space-between"]}
      >
        <Stack direction="column">
          <Flex width={["50%", "50%", "200px"]}>
            <LogoSvg />
          </Flex>
          <Text fontSize="xl">
            &copy; {new Date().getFullYear()} teraDigital Pty Ltd
          </Text>
          <Text fontSize="lg">Niche Software and Tech Recruitment Firm</Text>
          <Text>Brisbane, Queensland, Australia</Text>
          <Stack direction="row" fontSize={24}>
            <a
              href="https://www.linkedin.com/company/teradigital-services/"
              target="_blank"
            >
              <FaLinkedin />
            </a>
            <a href="mailto:info@teradigital.com.au">
              <FaEnvelope />
            </a>
          </Stack>
        </Stack>

        <Stack paddingTop={[16, 16, 0]}>
          <Text fontWeight="bold" fontSize="2xl">
            Specialists in
          </Text>
          <Stack spacing={1} color="gray.300">
            <Text>Software recruitment</Text>
            <Text>Embedded/Firmware recruitment</Text>
            <Text>QA recruitment</Text>
            <Text>DevOps/SRE/Cloud recruitment</Text>
            <Text>Data/AI/ML recruitment</Text>
            <Text>Product/Design recruitment</Text>
            <Text>Sales/Customer Success/Support recruitment</Text>
            <Text>Project Services/Delivery recruitment</Text>
            <Text>Leadership recruitment</Text>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  </Stack>
);
