import React from "react";
import { Stack, Heading } from "@chakra-ui/react";

import { Exclaim } from "./Exclaim";
import { NewsletterForm } from "./NewsletterForm";

import ShapeDotsSvg from "../images/svg/ShapeDots.svg";
import ShapeLineCircleSvg from "../images/svg/ShapeLineCircle.svg";

export const NewsletterExlcaim: React.FC = () => (
  <Exclaim
    topLeftAsset={<ShapeDotsSvg height={150} />}
    bottomRightAsset={<ShapeLineCircleSvg width={250} />}
  >
    <Stack direction="column" align="center" spacing={12} flex={1}>
      <Stack
        direction="column"
        align={["flex-start", "flex-start", "center"]}
        spacing={6}
      >
        <Heading fontSize={["3xl", "3xl", "4xl", "5xl"]}>
          Want to stay informed about the South East-QLD Software job market?
        </Heading>
        <Heading
          fontSize={["lg", "lg", "xl", "2xl"]}
          fontWeight="regular"
          opacity={0.8}
        >
          Subscribe for free to receive our Monthly, Quarterly, and FY job pulse
          reports with key market insights, stats and exclusive access to all
          the data we collect.
        </Heading>
      </Stack>

      <NewsletterForm />
    </Stack>
  </Exclaim>
);
